import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

const domRoot = document.getElementById("root");
let root;
if (domRoot.hasChildNodes()) {
  root = ReactDOM.hydrateRoot(domRoot, <App />);
} else {
  root = ReactDOM.createRoot(domRoot);
}
root.render(<App />);
